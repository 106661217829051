export default {
    Components: {
        CookieLayer: {
            AcceptButtonLabel: 'Accept',
            Description: 'We\'re concerned with your privacy. We use cookies to improve your experience in our website. We need your permission to keep doing it.',
            RejectButtonLabel: 'Reject'
        },
        Footer: {
            Disclaimer: 'BellaByAna - A Green Orbi Brand - All Rights Reserved 2022 - Powered by Shopify',
            Facebook: 'Facebook',
            Instagram: 'Instagram',
            LegalLinksTitle: 'Legal',
            NavigationLinksTitle: 'Brand',
            SocialLinksTitle: 'Social'
        },
        GlobalSearch: {
            Title: 'Search for your product...',
            NoResultsFounds: 'We found no results for "{query}"',
            Placeholder: 'Write the name of your product...'
        },
        HomeBanner: {
          CollectionName: '{name} collection',
          LinkLabel: 'Shop collection'
        },
        Navigation: {
          ShopAll: 'View all'
        },
        RangeFilter: {
          IntervalDescription: 'to'
        },
        Sort: {
            Ascending: 'Ascending',
            Descending: 'Descending',
            OrderByName: 'Order by Name',
            OrderByPrice: 'Order by Price',
            OrderByRelevance: 'Order by Relevance'
        },
        TermsAndConditions: {
            ModalTitle: 'Terms and Conditions'
        }
    },
    Views: {
        About: {
            CreatorDescription: 'The determination with which she chose what to wear at the age of 4 takes her, decades later, to London. In her luggage, in addition to the desire to study abroad, she already has a degree in Fashion Design, knowledge of weaving and natural dyeing. Ana for some, Catarina for others, what she knows about sewing and modeling, she learned as a student in Lisbon. Beyond the English Channel, remains the time to complete an MBA and to delve deeper into design and manufacturing, this time focusing on bags and shoes. It will, however, be the entry into the world of lingerie that will seduce her into the universe of swimwear. Back in Lisbon, happy to be back in the arms of the Atlantic, she gives shape to her imagination by handcrafting collections that are tailormade. As a result of her work with a platform curated by sustainable and ethical luxury brands, BellabyAna today expresses the environmental and social concerns of her creator, who perfectly combines the femininity of the models of a brand 100% developed and created in Portugal.\n',
            CreatorTitle: 'About me',
            StoryDescription: 'When creating BellabyAna, we sought to embrace and value the body of each and every woman with timeless pieces, whose national origin provides them with a touch of exclusivity. For our brand\'s bikinis and swimwear to accompany you through countless seasons, we prioritize quality and a generous combination of colors and patterns. It is a brand for women, conceived and made by women.\n And because we believe that femininity goes hand in hand with a lower environmental impact, in addition to materials being recovered on several beaches, by privileging BellaByAna, you are also contributing to cleaning the oceans.\n',
            StoryTitle: 'Our story',
            SustainabilityDescription: 'For its timeless design, but also for the quality of the materials, each BellabyAna piece is designed to last, with a view to promote more conscious consumption. By opting for plastic materials recovered from beaches all over the world, BellabyAna is favoring a production circuit, powered by in the countryside. But BellabyAna\'s commitment remains beyond the act of purchase: by purchasing a piece from the brand, you are contributing to the cleaning of our oceans. By choosing BellabyAna, each woman makes a difference, while valuing herself.\n',
            SustainabilityTitle: 'Sustainability',
            Subtitle: 'Learn about us and who we are',
            TeamTitle: 'Our team',
            Title: 'About us',
            ValuesDescription: 'Created in Portugal, BellabyAna bets on Portuguese production and the size of the collection is designed to warrant a certain exclusivity for special women.\n Because each woman is unique, the creator of BellabyAna thinks of designs and patterns to fit each personality. And who better than Portuguese women to embody BellabyAna\'s models? It is with this collaboration that BellabyAna nurtures employability in Portugal and promotes national talent.\n',
            ValuesTitle: 'Our values',
        },
        Cart: {
            BackButtonLabel: 'Continue shopping',
            CheckoutButtonLabel: 'Finish purchase',
            Legal: 'Taxes are included and shipping will be calculated before the purchase.',
            ProductsTitle: 'Your cart is empty | One item in your cart | {count} items in your cart',
            SidebarTitle: 'Summary',
            SubTitle: 'Manage the items in your cart',
            SubTotalLabel: 'Subtotal:',
            Title: 'Cart',
        },
        Contacts: {
            CloseSubmissionOverlayButton: 'Close',
            EmailDescription: 'Use our email to contact us regarding any issue you might find in your product, your order or even our website. We will reach out to you as soon as possible.',
            EmailInputLabel: 'Your email...',
            EmailTitle: 'Email us',
            MessageInputLabel: 'You message...',
            NameInputLabel: 'Your name...',
            PhoneInputLabel: 'Your phone number (optional)...',
            SubmissionError: 'An error has occurred while sending the message. Please try again later.',
            SubmissionSuccess: 'Message sent successfully',
            SubmitButtonLabel: 'Send message',
            Subtitle: 'Reach out to us using any of the channels mentioned below',
            Title: 'Contact Us',
        },
        Home: {
            AboutButtonLabel: 'About us',
            AboutSubtitle: 'Visit our about us page, and learn everything about our brand and goals',
            AboutTitle: 'Want to learn more about us?',
            CollectionsSubtitle: 'Explore our current clothing collections',
            CollectionsTitle: 'Collections',
            ContactsButtonLabel: 'Contact us',
            ContactsSubtitle: 'Visit our contacts page to find out how you can reach us',
            ContactsTitle: 'Get in touch with us',
            ShowcaseSubtitle: 'Our best selling pieces',
            ShowcaseTitle: 'Showcase',
        },
        Legal: {
          PrivacyPolicy: 'Privacy Policy',
          ReturnsExchanges: 'Returns & Exchanges',
          TermsConditions: 'Terms & Conditions'
        },
        Partners: {
          CastilhoDescription: 'A new store, in Castil as always. Women\'s Fashion items, accessories, plants, ceramics, Men\'s Fashion, men\'s accessories, among others, all in one store. The brands of the moment.\n\n At Castilho Concept Store you will find everything you are looking for as well as the latest market trends at the best price.',
          GotaDaguaDescription: 'Gota Dagua Surf School & Surf Camp was founded by four friends that share a common passion. To live a fulfilled life and share the Portugese surf lifestyle with the world. We dream of being a pillar of excellence within the surf industry, not just in Portugal but all around the world. Every year we are focused on improving the quality of our service by adding exceptional people to our team and continuously improving upon our business model. We are dedicated to providing the surf holiday of a lifetime for all our guests, delivering consistently high quality surf coaching and creating unforgettable memories.\n\n The family that we have gathered along our journey is what keeps us motivated and continues to inspire us every day to keep striving for our dreams. We are a surf community spread across all parts of the world and we want to continue expanding with each year that passes.',
          SubTitle: 'Where you can find us',
          Title: 'Partners'
        },
        ProductDetails: {
            AddToCartLabel: 'Add to Cart',
            AmountInCartMessage: '{count} already in cart',
            AvailableStockMessage: '{count} available for purchase',
            BuyNowLabel: 'Buy it Now',
            CloseOverlayButton: 'Close',
            LowStockMessage: 'Only a few units left',
            MaxAmountExceededMessage: 'Maximum amount already in cart',
            ProductAddedErrorMessage: 'Error adding product to cart',
            ProductAddedSuccessfullyMessage: 'Product added to cart',
            QuantityLabel: 'Quantity',
            RelatedProductHeader: 'Check other related products',
            SizeGuideButtonLabel: 'Size guide',
            UnavailableMessage: 'Unavailable'
        },
        Shop: {
          AvailableOptionLabel: 'Available',
          AvailabilityFilterLabel: 'Availability',
          ApplyFiltersButtonLabel: 'Apply filters',
          BottomsOptionLabel: 'Bottoms',
          LoadMoreButtonLabel: 'Load More',
          MenOptionLabel: 'Men',
          PriceFilterLabel: 'Price',
          ProductCountLabel: 'No products | Viewing one product | Viewing {count} products',
          SubTitle: 'Browse our catalogue of products and find exactly what your are looking for',
          TagsFilterLabel: 'Tags',
          Title: 'Shop',
          TopsOptionLabel: 'Tops',
          WomenOptionLabel: 'Women'
        },
        SizeGuide: {
          Subtitle: 'Discover which size fits you best',
          Title: 'Size guide'
        }
    },
}
