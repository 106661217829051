import { execute } from '@/config/graphql.config';

// Models
import ProductModel from '@/models/product.model';
import ProductVariantModel from '@/models/product-variant.model';
import ProductVariantOptionModel from "@/models/product-variant-option.model";

// Queries
import fetchProductsQuery from '@/graphql/fetchProducts.query.graphql';
import fetchProductsByCollectionQuery from '@/graphql/feathProductByCollection.graphql';
import fetchProductDetailsQuery from '@/graphql/fetchProductDetails.query.graphql';
import fetchProductRecommendations from '@/graphql/fetchProductRecommendations.graphql';

export type ProductData = {
    id: string;
    title: string;
    descriptionHtml: string;
    images: {
        edges: {
            node: {
                transformedSrc: string;
            };
        }[];
    }
    variants: {
        edges: {
            node: {
                id: string;
                title: string;
                compareAtPriceV2: {
                    amount: number;
                }
                priceV2: {
                    amount: number;
                },
                availableForSale: boolean;
                quantityAvailable: number;
                selectedOptions: {
                    name: string;
                    value: string;
                }[]
            }
        }[];
    }
    tags: string;
}

export function parseProductData(data: ProductData): ProductModel {
    const variantOptions: ProductVariantOptionModel[] = [];
    const variants: ProductVariantModel[] = data.variants.edges.map(variant => {
        const variantIdentifiers = variant.node.selectedOptions.map(option => ({
            id: option.name,
            value: option.value
        }));

        // Add variant options to list if they don't exist
        variantIdentifiers.forEach(variantOption => {
            let selectedVariantOption = variantOptions.find(option => option.id === variantOption.id);

            // If variant option does not exist yet, add it
            if (!selectedVariantOption) {
                selectedVariantOption = new ProductVariantOptionModel({
                    id: variantOption.id,
                    options: []
                });

                variantOptions.push(selectedVariantOption);
            }

            // Check if option value is already added, and add it otherwise
            if (selectedVariantOption.options.findIndex(option => option.value === variantOption.value) === -1) {
                selectedVariantOption.options.push({
                    label: variantOption.value,
                    value: variantOption.value,

                })
            }
        })

        return new ProductVariantModel({
            id: variant.node.id,
            price: variant.node.priceV2.amount,
            oldPrice: variant.node.compareAtPriceV2?.amount,
            availableForSale: variant.node.availableForSale,
            availableQuantity: variant.node.quantityAvailable,
            variantIdentifiers
        });
    })

    return new ProductModel({
        description: data.descriptionHtml,
        id: data.id,
        images: data.images.edges.map(edge => edge.node.transformedSrc),
        name: data.title,
        variants,
        variantOptions
    })
}

export function getProducts(sortType: string, sortDirection: string, amount: number, afterCursor?: string | null, query = ''): Promise<{
  cursor: string,
  hasNextPage: boolean,
  products: ProductModel[]
}> {
    const variables = {
      after: afterCursor,
      amount,
      query,
      reverse: sortDirection === 'desc',
      sort: sortType
    }

    return execute(fetchProductsQuery, variables)
        .then(result => {
            const products = result.data.products.edges.map((edge: { node: ProductData }) => parseProductData(edge.node));

            return {
              cursor: products.length > 0 ? result.data.products.edges[result.data.products.edges.length - 1].cursor : null,
              hasNextPage: result.data.products.pageInfo.hasNextPage,
              products
            };
        });
}

export function getProductsByCollection(collectionHandle: string): Promise<ProductModel[]> {
  return execute(fetchProductsByCollectionQuery, { collectionHandle })
    .then(result => {
      return result.data.collection.products.edges.map((edge: { node: ProductData }) => parseProductData(edge.node));
    });
}

export function getProductDetails(productId: string): Promise<ProductModel>  {
    return execute(fetchProductDetailsQuery, { productId })
        .then(result => {
            return parseProductData(result.data.product);
        });

}

export function getProductRecommendations(productId: string): Promise<ProductModel[]> {
  return execute(fetchProductRecommendations, { productId })
    .then(result => {
      return result.data.productRecommendations.map((product: ProductData) => parseProductData(product));
    });
}
