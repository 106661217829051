import RootModel from "@/models/_root.model";
import ProductModel from "@/models/product.model";
import i18n from "@/config/i18n.config";

export interface CollectionModelData {
  description: string;
  id: string;
  image: string;
  products?: ProductModel[];
  title: string;
}

export default class CollectionModel extends RootModel {

  description: string;
  id: string;
  image: string;
  products: ProductModel[];
  title: string;

  constructor(data: CollectionModelData) {
    super();

    this.description = data.description;
    this.id = data.id;
    this.image = data.image;
    this.products = data.products || [];
    this.title = data.title;
  }

  get localizedTitle() {
    const titleEntries = this.title.split('||');

    if (i18n.locale === 'en' && !!titleEntries[0]) {
      return titleEntries[0];
    }
    if (i18n.locale === 'pt' && !!titleEntries[1]) {
      return titleEntries[1];
    }

    return this.title;
  }

  get localizedDescription() {
    const element = document.createElement('html');
    element.innerHTML = this.description;

    const localizedSection = element.querySelectorAll(`[lang="${i18n.locale}"]`);

    if (localizedSection.length > 0) {
      return localizedSection[0].innerHTML;
    }
    else {
      return this.description;
    }
  }
}
