import Vue from 'vue'

import App from './App.vue'
import router from './config/router.config'
import i18n from './config/i18n.config';
import store from './store'

// Global Styles
import '@/assets/sass/index.scss';

Vue.config.productionTip = false

// Price formatting filter
Vue.filter('monetary', (value: string) => {
  if (!value) return '';

  return parseFloat(value).toFixed(2) + ' €';
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
