
import { Component, Vue } from 'vue-property-decorator';

// Config
import { RouteNames } from '@/config/router.config';

// Components
import LanguagePicker from '@/components/language-picker.component.vue';

// Payment methods
import mastercard from '../assets/images/paymentMethods/mastercard.svg';
import visa from '../assets/images/paymentMethods/visa.svg';
import mbway from '../assets/images/paymentMethods/mbway.svg';
import multibanco from '../assets/images/paymentMethods/multibanco.svg';
import paypal from '../assets/images/paymentMethods/paypal.svg';

@Component({
  components: {
    LanguagePicker
  }
})
export default class Footer extends Vue {

  navLinks = [
    {
      name: this.$t('Views.Shop.Title'),
      to: {
        name: RouteNames.Shop
      }
    },
    {
      name: this.$t('Views.About.Title'),
      to: {
        name: RouteNames.About
      }
    },
    {
      name: this.$t('Views.Partners.Title'),
      to: {
        name: RouteNames.Partners
      }
    },
    {
      name: this.$t('Views.Contacts.Title'),
      to: {
        name: RouteNames.Contacts
      }
    }
  ];

  legalLinks = [
    {
      name: this.$t('Views.Legal.TermsConditions'),
      to: {
        name: RouteNames.Legal,
        params: {
          page: 'terms-conditions'
        }
      }
    },
    {
      name: this.$t('Views.Legal.PrivacyPolicy'),
      to: {
        name: RouteNames.Legal,
        params: {
          page: 'privacy-policy'
        }
      }
    },
    {
      name: this.$t('Views.Legal.ReturnsExchanges'),
      to: {
        name: RouteNames.Legal,
        params: {
          page: 'returns-exchanges'
        }
      }
    },
  ];

  socialLinks = [
    {
      name: this.$t('Components.Footer.Facebook'),
      to: 'https://www.facebook.com/bellabyana'
    },
    {
      name: this.$t('Components.Footer.Instagram'),
      to: 'https://www.instagram.com/bellabyana'
    },
  ];

  paymentMethods = [
      mastercard,
      visa,
      mbway,
      multibanco,
      paypal
  ];

}
