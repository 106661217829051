import { ActionContext, Module } from 'vuex';

import { RootState } from '@/store/index';

// Config
import i18n from '@/config/i18n.config';

// Models
import FilterModel from "@/models/filter.model";
import MultiselectFilterModel from "@/models/multiselect-filter.model";
import ProductModel from "@/models/product.model";
import RangeFilterModel from "@/models/range-filter.model";

// Resources
import { getProducts } from "@/resources/products.resource";

export interface ProductSearchStoreState {
  loading: boolean;
  products: ProductModel[];
  //selectedCategory: string | null,
  //categoryTree: CategoryModel,
  filtering: {
    filters: FilterModel[],
    values: {
      [key: string]: any
    }
  },
  pagination: {
    hasNextPage: boolean;
    currentCursor: string | null;
    itemPerPage: number;
  }
  sort: {
    current: string;
    direction: string;
  }
}

export default class ProductSearchStoreModule implements Module<ProductSearchStoreState, RootState>{
    namespaced = true;

    state = {
        loading: false,
        filtering: {
          filters: [
            new MultiselectFilterModel({
              id: 'tag',
              name: i18n.t('Views.Shop.TagsFilterLabel') as string,
              options: [
                {
                  label: i18n.t('Views.Shop.TopsOptionLabel') as string,
                  value: 'tops'
                },
                {
                  label: i18n.t('Views.Shop.BottomsOptionLabel') as string,
                  value: 'bottoms'
                },
                {
                  label: i18n.t('Views.Shop.MenOptionLabel') as string,
                  value: 'men'
                },
                {
                  label: i18n.t('Views.Shop.WomenOptionLabel') as string,
                  value: 'women'
                }
              ]
            }),
            new MultiselectFilterModel({
              id: 'available_for_sale',
              name: i18n.t('Views.Shop.AvailabilityFilterLabel') as string,
              options: [
                {
                  label: i18n.t('Views.Shop.AvailableOptionLabel') as string,
                  value: 'true'
                }
              ]
            }),
            new RangeFilterModel({
              id: 'variants.price',
              name: i18n.t('Views.Shop.PriceFilterLabel') as string,
              minValue: 0,
              maxValue: 150
            })
          ],
          values: {
            'tag': [],
            'available_for_sale': [],
            'variants.price': [0, 150]
          }
        },
        pagination: {
          currentCursor: null,
          hasNextPage: false,
          itemPerPage: 12
        },
        sort: {
          current: 'RELEVANCE',
          direction: 'asc'
        },
        products: []
    };

    mutations = {
        setFilterValue (state: ProductSearchStoreState, { id, value }: { id: string, value: any }) {
          state.filtering.values = {
            ...state.filtering.values,
            [id]: value
          }
        },

        setLoading (state: ProductSearchStoreState, { loading }: { loading: boolean; }) {
            state.loading = loading;
        },

        setPagination (state: ProductSearchStoreState, { cursor, hasNextPage }: { cursor?: string, hasNextPage?: boolean }) {
          state.pagination.currentCursor = typeof cursor !== 'undefined' ? cursor : state.pagination.currentCursor;
          state.pagination.hasNextPage = typeof hasNextPage !== 'undefined' ? hasNextPage : state.pagination.hasNextPage;
        },

        setProducts (state: ProductSearchStoreState, { products }: { products: ProductModel[] }) {
            state.products = products
        },

        setSort (state: ProductSearchStoreState, { sort, direction }: { sort?: string; direction?: string }) {
          state.sort.current = sort || state.sort.current;
          state.sort.direction = direction || state.sort.direction;
        }
    };

    actions = {
        setCurrentSort ({ commit, dispatch }: ActionContext<ProductSearchStoreState, RootState>, { sort, direction }: { sort?: string; direction?: string }) {
          commit('setSort', {
            sort,
            direction
          });

          // Reload products
          return dispatch('searchProducts', true);
        },

        setFilterValue ({ commit, dispatch }: ActionContext<ProductSearchStoreState, RootState>, { id, value }: { id: string, value: any }) {
          commit('setFilterValue', {
            id,
            value
          });
          return dispatch('searchProducts', true);
        },

        searchProducts ({ commit, state }: ActionContext<ProductSearchStoreState, RootState>, reset?: boolean) {
            commit('setLoading', {
                loading: true
            });

            if (reset) {
              commit('setPagination', {
                cursor: null,
                hasNextPage: false
              });
            }

            const query = state.filtering.filters
              .map(filter => filter.getFilterQueryFromValue(state.filtering.values[filter.id]))
              .filter(query => query !== '')
              .join(' AND ')

            return getProducts (
              state.sort.current,
              state.sort.direction,
              state.pagination.itemPerPage,
              state.pagination.currentCursor,
              query
            )
                .then(({ cursor, hasNextPage, products}) => {
                    commit('setPagination', {
                      cursor,
                      hasNextPage
                    });
                    commit('setProducts', {
                      products: reset ? products : state.products.concat(products)
                    });
                })
                .finally(() => {
                    commit('setLoading', {
                        loading: false
                    });
                })
        }
    };
}
