
import { Component, Vue } from "vue-property-decorator";

// Config
import { SELECTED_LOCALE_KEY } from "@/config/i18n.config";

// Services
import LocalStorageService from '@/services/local-storage.service';

@Component
export default class LanguagePicker extends Vue {
  options = [
    {
      label: 'English (EN)',
      value: 'en'
    },
    {
      label: 'Português (PT)',
      value: 'pt'
    }
  ];

  selectedLanguage = this.$i18n.locale;

  handleLanguageChange(): void {
    LocalStorageService.setKey(SELECTED_LOCALE_KEY, this.selectedLanguage);
    window.location.reload();
  }
}
