import { execute } from '@/config/graphql.config';

// Models
import CartModel from '@/models/cart.model';
import CartEntryModel from '@/models/cart-entry.model';
import CartEntryVariantModel from "@/models/cart-entry-variant.model";

// Queries
import getCartQuery from '../graphql/fetchCart.query.graphql';

// Mutations
import createCartMutation from '../graphql/createCart.mutation.graphql';
import setProductsInCart from '../graphql/setProductsInCart.mutation.graphql';

export type CartData = {
    id: string;
    webUrl: string;
    lineItems: {
        edges: {
            node: {
                title: string;
                quantity: string;
                variant: {
                    id: string;
                    title: string;
                    image: {
                        transformedSrc: string;
                    };
                    compareAtPriceV2: {
                      amount: string;
                    };
                    priceV2: {
                        amount: string;
                    };
                    quantityAvailable: number;
                    selectedOptions: {
                        name: string;
                        value: string;
                    }[]
                }
            }
        }[];
    };
    totalPriceV2: {
        amount: string,
        currencyCode: string
    };
    completedAt: string;
};

function parseCartData(data: CartData) {
    return new CartModel({
        id: data.id,
        totalPrice: data.totalPriceV2.amount,
        checkoutLink: data.webUrl,
        isCompleted: !!data.completedAt,
        entries: data.lineItems.edges
            .map(entry => new CartEntryModel({
                id: entry.node.variant.id,
                imageUrl: entry.node.variant.image.transformedSrc,
                maxQuantity: entry.node.variant.quantityAvailable,
                name: entry.node.title,
                price: parseFloat(entry.node.variant.priceV2.amount),
                oldPrice: entry.node.variant.compareAtPriceV2 !== null ? parseFloat(entry.node.variant.compareAtPriceV2.amount) : NaN,
                quantity: parseInt(entry.node.quantity),
                variants: entry.node.variant.selectedOptions.map(option => new CartEntryVariantModel(option))
            }))
    });
}

export function createCart() {
    return execute(createCartMutation)
        .then(result => new CartModel({
            id: result.data.checkoutCreate.checkout.id,
            totalPrice: result.data.checkoutCreate.checkout.totalPriceV2.amount,
            checkoutLink: result.data.checkoutCreate.checkout.webUrl,
            isCompleted: false,
            entries: []
        }));
}

export function getCart(cartId: string) {
    const variables = {
        cartId
    };

    return execute(getCartQuery, variables)
        .then(result => {
          if (result.data.node) {
            return parseCartData(result.data.node)
          }

          return null;
        })
}

export function setProducts(cartId: string, cartEntries: CartEntryModel[]) {
    const variables = {
        cartId,
        products: cartEntries.map((entry) => ({
            variantId: entry.id,
            quantity: entry.quantity
        }))
    };

    return execute(setProductsInCart, variables)
        .then(result => parseCartData(result.data.checkoutLineItemsReplace.checkout))
}
