import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

export enum RouteNames {
    About = 'About',
    Cart = 'Cart',
    Collection = 'Collection',
    Contacts = 'Contacts',
    Home = 'Home',
    Legal = 'Legal',
    Partners = 'Partners',
    ProductDetails = 'ProductDetails',
    Shop = 'Shop',
    SizeGuide = 'SizeGuide',
}

Vue.use({
    install: (Vue) => {
        Vue.prototype.$routeNames = RouteNames;
    }
})

const routes: Array<RouteConfig> = [
    {
        name: RouteNames.Home,
        path: '/',
        component: () => import('@/views/home.view.vue')
    },
    {
        name: RouteNames.Shop,
        path: '/shop',
        component: () => import('@/views/shop.view.vue'),
        meta: {
            isInNavbar: true
        }
    },
    {
        name: RouteNames.Collection,
        path: '/collections/:collectionId',
        component: () => import('@/views/collection.view.vue'),
        meta: {
            isInNavbar: false
        }
    },
    {
        name: RouteNames.ProductDetails,
        path: '/product/:id',
        component: () => import('@/views/product-details.view.vue'),
    },
    {
      name: RouteNames.Cart,
      path: '/cart',
      component: () => import('@/views/cart.view.vue'),
    },
    {
        name: RouteNames.About,
        path: '/about',
        component: () => import('@/views/about.view.vue'),
        meta: {
            isInNavbar: true
        }
    },
    {
      name: RouteNames.Partners,
      path: '/partners',
      component: () => import('@/views/partners.view.vue'),
    },
    {
        name: RouteNames.Legal,
        path: '/legal/:page',
        component: () => import('@/views/legal.view.vue'),
        meta: {
          isInNavbar: true
        }
    },
    {
      name: RouteNames.SizeGuide,
      path: '/size-guide',
      component: () => import('@/views/size-guide.view.vue'),
      meta: {
        isInNavbar: true
      }
    },
    {
        name: RouteNames.Contacts,
        path: '/contacts',
        component: () => import('@/views/contacts.view.vue'),
        meta: {
            isInNavbar: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    next();
})

export default router
