export default {
    Components: {
        CookieLayer: {
            AcceptButtonLabel: 'Aceitar',
            Description: 'Nós preocupamo-nos com a sua privacidade. Utilizamos cookies para melhorar a sua experiência no nosso site. Precisamos da sua autorização para o continuar a fazer.',
            RejectButtonLabel: 'Rejeitar'
        },
        Footer: {
            Disclaimer: 'BellaByAna - Uma marca Green Orbi - Todos os direitos reservados 2022 - Powered by Shopify',
            Facebook: 'Facebook',
            Instagram: 'Instagram',
            LegalLinksTitle: 'Legal',
            NavigationLinksTitle: 'Marca',
            SocialLinksTitle: 'Social'
        },
        GlobalSearch: {
          Title: 'Procure o seu produto...',
          NoResultsFounds: 'Não encontrámos resultados para "{query}"',
          Placeholder: 'Escreva o nome do produto...'
        },
        HomeBanner: {
          CollectionName: 'Coleção {name}',
          LinkLabel: 'Ver coleção'
        },
        Navigation: {
          ShopAll: 'Ver tudo'
        },
        RangeFilter: {
            IntervalDescription: 'até'
        },
        Sort: {
            Ascending: 'Ascendente',
            Descending: 'Descendente',
            OrderByName: 'Ordernar por Nome',
            OrderByPrice: 'Ordernar por Preço',
            OrderByRelevance: 'Ordernar por Relevância'
        },
        TermsAndConditions: {
          ModalTitle: 'Termos e Condições'
        }
    },
    Views: {
        About: {
            CreatorDescription: 'A determinação com que escolhe o que vestir aos 4 anos de idade leva-a, décadas mais tarde, até Londres. Na bagagem, além do desejo de estudar fora, leva já a licenciatura em Design de Moda, conhecimentos sobre tecelagem e tingimento natural. Ana para uns, Catarina para outros, o que sabe sobre costura e modelagem, aprende-o enquanto estudante em Lisboa. Para lá do canal da Mancha, permanece o tempo de concluir um MBA e de aprofundar mais sobre design e confecção, desta vez de malas e sapatos. Será, no entanto, a entrada no mundo da lingerie a seduzi-la para o universo dos fatos- de-banho. De regresso a Lisboa, feliz de voltar para os braços do Atlântico, dá forma à sua imaginação ao confeccionar artesanalmente colecções que faz por medida. Fruto da sua passagem por uma plataforma com curadoria de marcas de luxo sustentáveis e éticas, a BellabyAna expressa hoje as preocupações ambientais e sociais da sua criadora que esta alia na perfeição à feminilidade dos modelos de uma marca 100% desenvolvida e criada em Portugal.\n',
            CreatorTitle: 'Sobre mim',
            StoryDescription: 'Ao criar a BellabyAna, procurámos valorizar o corpo de toda e qualquer mulher com peças intemporais, cuja origem nacional lhes confere um toque de exclusividade. Para que os biquínis e fatos-de-banho da nossa marca a acompanhem por inúmeras estações, privilegiamos a qualidade e uma combinação generosa de cores e padrões. É uma marca para mulheres, concebida e realizada por mulheres.\n E por acreditarmos que a feminilidade caminha de mãos dadas com um menor impacto ambiental, além dos materiais serem recuperados em diversas praias, ao privilegiar a BellaByAna está, ainda, a contribuir para a limpeza do nosso oceano.\n',
            StoryTitle: 'A nossa história',
            SustainabilityDescription: 'Pelo seu design intemporal, mas também pela qualidade dos materiais, cada peça da BellabyAna é pensada para durar, numa óptica de promoção de um consumo mais consciente. Ao optar por materiais em plástico recuperados em praias um pouco por todo o mundo, a BellabyAna está a privilegiar um circuito, alimentado com o recurso à produção no centro do país. Mas o compromisso da BellabyAna permanece para lá do acto da compra: ao adquirir cada peça da marca, está a contribuir para a limpeza do nosso oceano. Ao escolher a BellabyAna, cada mulher faz a diferença, enquanto se valoriza.\n',
            SustainabilityTitle: 'Sustentabilidade',
            Subtitle: 'Aprenda acerca de nós e quem somos',
            TeamTitle: 'A nossa equipa',
            Title: 'Sobre nós',
            ValuesDescription: 'De criação nacional, a BellabyAna aposta na produção portuguesa, cuja dimensão é pensada para assegurar uma certa exclusividade a mulheres especiais.\n Porque cada mulher é única, a criadora da BellabyAna pensa em modelos e padrões para satisfazer cada personalidade. E quem melhor que as mulheres Portuguesas para darem corpo aos modelos da BellabyAna? É com a sua colaboração que a BellabyAna alimenta a empregabilidade no nosso país e promove o seu talento.\n',
            ValuesTitle: 'Os nossos valores',
        },
        Cart: {
            BackButtonLabel: 'Continuar a comprar',
            CheckoutButtonLabel: 'Finalizar compra',
            Legal: 'Imposto incluído e envio calculado na finalização da compra.',
            ProductsTitle: 'O seu carrinho está vazio | Um item no seu carrinho | {count} items no seu carrinho',
            SidebarTitle: 'Resumo',
            SubTitle: 'Gira os items no seu carrinho',
            SubTotalLabel: 'Subtotal:',
            Title: 'Carrinho',
        },
        Contacts: {
            CloseSubmissionOverlayButton: 'Fechar',
            EmailDescription: 'Utilize o nosso email para nos contactar com qualquer problema que tenha com o seu produto, a sua encomenda ou mesmo o nosso website. Nós entraremos em contacto consigo os mais cedo possível.',
            EmailInputLabel: 'O teu email...',
            EmailTitle: 'Por email',
            MessageInputLabel: 'A tua mensagem...',
            NameInputLabel: 'O teu nome...',
            PhoneInputLabel: 'O teu número de telefone (opcional)...',
            SubmissionError: 'Ocorreu um erro a enviar a mensagem. Por favor tente novamente mais tarde.',
            SubmissionSuccess: 'Mensagem enviada com sucesso',
            SubmitButtonLabel: 'Enviar mensagem',
            Subtitle: 'Fale connosoco usando quaisquer dos canais abaixo',
            Title: 'Contactos',
        },
        Home: {
            AboutButtonLabel: 'Sobre nós',
            AboutSubtitle: 'Visite a nossa página acerca de nós, e aprenda tudo acerca da nossa marca e valores',
            AboutTitle: 'Quer saber mais acerca de nós?',
            CollectionsSubtitle: 'Explore as nossas coleções actuais',
            CollectionsTitle: 'Coleções',
            ContactsButtonLabel: 'Contactos',
            ContactsSubtitle: 'Visit our contacts page to find out how you can reach us',
            ContactsTitle: 'Entre em contacto connosco',
            ShowcaseSubtitle: 'As nossas peças mais vendidas',
            ShowcaseTitle: 'Destaques',
        },
        Legal: {
            PrivacyPolicy: 'Política de Privacidade',
            ReturnsExchanges: 'Trocas e Devoluções',
            TermsConditions: 'Termos e Condições'
        },
        Partners: {
          CastilhoDescription: 'Uma nova loja, no Castil de sempre. Moda de Senhora, acessórios, plantas, cerâmica, Moda de Homem, acessórios de homem, entre outros artigos, tudo numa só loja. As marcas do momento.\n\n Na Castilho Concept Store vai encontrar tudo o que procura tal como as mais recentes tendências do mercado ao melhor preço.',
          GotaDaguaDescription: 'Gota Dagua Surf School & Surf Camp foi fundada por quatro amigos que compartilham uma paixão em comum. Viver uma vida plena e partilhar o estilo de vida do surf português com o mundo. Sonhamos ser um pilar de excelência na indústria do surf, não só em Portugal mas em todo o mundo. Todos os anos, estamos focados em melhorar a qualidade de nosso serviço, adicionando pessoas excepcionais à nossa equipe e melhorando continuamente nosso modelo de negócios. Estamos empenhados em proporcionar as férias de surf de uma vida a todos os nossos hóspedes, proporcionando um treino de surf de alta qualidade de forma consistente e criando memórias inesquecíveis.\n\n A família que reunimos ao longo de nossa jornada é o que nos motiva e continua a nos inspirar todos os dias a seguir buscando nossos sonhos. Somos uma comunidade de surf espalhada por todas as partes do mundo e queremos continuar a expandir a cada ano que passa.',
          SubTitle: 'Descubra os nossos pontos de venda',
          Title: 'Parceiros'
        },
        ProductDetails: {
            AddToCartLabel: 'Adicionar ao Cesto',
            AmountInCartMessage: '{count} adicionado ao carrinho | {count} adicionados ao carrinho',
            AvailableStockMessage: '{count} disponível para compra | {count} disponíveis para compra',
            BuyNowLabel: 'Compra já',
            CloseOverlayButton: 'Fechar',
            LowStockMessage: 'Apenas restam algumas unidades',
            MaxAmountExceededMessage: 'Quantidade máxima já no carrinho',
            ProductAddedErrorMessage: 'Erro a adicionar produto ao carrinho',
            ProductAddedSuccessfullyMessage: 'Produto adicionado ao carrinho',
            QuantityLabel: 'Quantidade',
            RelatedProductHeader: 'Veja outros produtos relacionados',
            SizeGuideButtonLabel: 'Guia de tamanhos',
            UnavailableMessage: 'Esgotado'
        },
        Shop: {
          AvailableOptionLabel: 'Disponível',
          AvailabilityFilterLabel: 'Disponibilidade',
          ApplyFiltersButtonLabel: 'Aplicar filtros',
          BottomsOptionLabel: 'Bottoms',
          LoadMoreButtonLabel: 'Carregar mais',
          MenOptionLabel: 'Homem',
          PriceFilterLabel: 'Preço',
          ProductCountLabel: 'Nenhum produto | A visualizar um produto | A visualizar {count} produtos',
          SubTitle: 'Veja o nosso catálogo e encontre exactamente o que está à procura',
          TagsFilterLabel: 'Tags',
          Title: 'Loja',
          TopsOptionLabel: 'Tops',
          WomenOptionLabel: 'Mulher'
        },
        SizeGuide: {
          Subtitle: 'Descubra que tamanho lhe fica melhor',
          Title: 'Guia de tamanhos'
        },
    },
}
