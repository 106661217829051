import FilterModel, { FilterModelData, ProductFilterType } from '@/models/filter.model';

export interface RangeFilterData extends FilterModelData {
  maxValue: number;
  minValue: number;
  prefixLabel?: string;
  suffixLabel?: string;
}

export default class RangeFilterModel extends FilterModel {
  maxValue: number;
  minValue: number;
  prefixLabel?: string;
  suffixLabel?: string;

  constructor (data: RangeFilterData) {
    super(data);

    this.type = ProductFilterType.Range;

    this.maxValue = data.maxValue;
    this.minValue = data.minValue;
    this.prefixLabel = data.prefixLabel;
    this.suffixLabel = data.suffixLabel;
  }

  public getFilterQueryFromValue(value: number[]): string {
    // Check if we are seeing max only or min and max values
    if (value.length === 1) {
      // If value equals max value, then no value is selected and we should not return anything
      if (value[0] === this.maxValue) {
        return ''
      }

      return `${this.id}:<=${value[0]}`;
    }
    else {
      // If both values equal min and max values, then no value is selected and we should not return anything
      if (value[0] === this.minValue && value[1] === this.maxValue) {
        return ''
      }

      return `${this.id}:>=${value[0]} AND ${this.id}:<=${value[1]}`;
    }
  }
}
