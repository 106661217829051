export enum ProductFilterType {
  Range,
  MultiSelect,
  Text
}

export interface FilterModelData {
  id: string;
  name: string;
}

export default class FilterModel {
  id: string;
  name: string;
  type!: ProductFilterType;

  constructor (data: FilterModelData) {
    this.id = data.id;
    this.name = data.name;
  }

  getFilterQueryFromValue(value: any): string {
    return `${this.id}:${value}`;
  }
}
