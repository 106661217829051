import { execute } from "@/config/graphql.config.ts";

// Models
import CollectionModel from "@/models/collection.model";

// Queries
import fetchCollectionsIndex from '../graphql/fetchCollectionsIndex.query.graphql';
import fetchCollectionByIdQuery from '../graphql/fetchCollectionById.query.graphql';

// Resources
import { parseProductData, ProductData } from "@/resources/products.resource";

export type CollectionData = {
    descriptionHtml: string;
    handle: string;
    image: {
      url: string;
    };
    products?: {
      edges: {
        node: ProductData
      }[]
    },
    title: string;
}

function parseCollectionData(data: CollectionData) {
  return new CollectionModel({
    description: data.descriptionHtml,
    id: data.handle,
    image: data.image.url,
    products: data.products?.edges.map(edge => parseProductData(edge.node)),
    title: data.title
  })
}

export function getCollectionsIndex() {
  return execute(fetchCollectionsIndex)
    .then(result => {
      return result.data.collections.edges.map(edge => ({
        handle: edge.node.handle,
        bannerPosition: edge.node.bannerPosition?.value,
        frontPageGridPosition: edge.node.frontPageGridPosition?.value,
        navigationPosition: edge.node.navigationPosition?.value,
      }))
    });
}

export function getCollectionsByHandles(handles: string[], maxImageSize?: number) {
  let query = handles
    .map(handle => getCollectionFragment(handle))
    .join('');

  query = `query { ${query} }`

  return execute(query)
    .then(result => {
      return handles
        .map(handle => result.data[normalizeCollectionHandle(handle)])
        .map(data => parseCollectionData(data))
    });

  function normalizeCollectionHandle(handle) {
    let normalizedHandle = handle;

    while (normalizedHandle.indexOf('-') !== -1) {
      normalizedHandle = normalizedHandle.replace('-','');
    }

    return normalizedHandle;
  }

  function getCollectionFragment(handle) {
    return `
    ${normalizeCollectionHandle(handle)}: collection(handle: "${handle}") {
        handle,
        image {
            url${maxImageSize ? `(transform: { maxWidth: ${maxImageSize}, maxHeight: ${maxImageSize}})` : ''}
        },
        title
    },
    `
  }
}

export function getFrontPageCollections() {
  return getCollectionsIndex()
    .then(collectionsIndex => {
      const neededCollections = collectionsIndex
        .filter(collection => !!collection.frontPageGridPosition);
      const neededHandles = neededCollections
        .map(collection => collection.handle);

      return getCollectionsByHandles(neededHandles, 500)
        .then(collections => collections.sort((collectionA, collectionB) => {
          const collectionAPosition = neededCollections.find(collection => collection.handle === collectionA.id)?.frontPageGridPosition;
          const collectionBPosition = neededCollections.find(collection => collection.handle === collectionB.id)?.frontPageGridPosition;

          return collectionAPosition - collectionBPosition;
        }));
    });
}

export function getBannerCollections() {
  return getCollectionsIndex()
    .then(collectionsIndex => {
      const neededCollections = collectionsIndex
        .filter(collection => !!collection.bannerPosition);
      const neededHandles = neededCollections
        .map(collection => collection.handle);

      return getCollectionsByHandles(neededHandles)
        .then(collections => collections.sort((collectionA, collectionB) => {
          const collectionAPosition = neededCollections.find(collection => collection.handle === collectionA.id)?.bannerPosition;
          const collectionBPosition = neededCollections.find(collection => collection.handle === collectionB.id)?.bannerPosition;

          return collectionAPosition - collectionBPosition;
        }));
    })
}

export function getNavigationCollections() {
  return getCollectionsIndex()
    .then(collectionsIndex => {
      const neededCollections = collectionsIndex
        .filter(collection => !!collection.navigationPosition);
      const neededHandles = neededCollections
        .map(collection => collection.handle);

      if (neededHandles.length > 0) {
        return getCollectionsByHandles(neededHandles)
          .then(collections => collections.sort((collectionA, collectionB) => {
            const collectionAPosition = neededCollections.find(collection => collection.handle === collectionA.id)?.bannerPosition;
            const collectionBPosition = neededCollections.find(collection => collection.handle === collectionB.id)?.bannerPosition;

            return collectionAPosition - collectionBPosition;
          }));
      } else {
        return Promise.resolve([]);
      }
    })
}

export function getCollectionById(collectionId: string) {
  return execute(fetchCollectionByIdQuery, { collectionId })
    .then(result => parseCollectionData(result.data.collection));
}
