
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

// Config
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import LoadingSpinner from "@/components/loading-spinner.component.vue";
import ProductTile from "@/components/product-tile.component.vue";

// Models
import ProductModel from "@/models/product.model";

// Resources
import { getProducts } from "@/resources/products.resource";

@Component({
  components: {
    LoadingSpinner,
    ProductTile
  }
})
export default class GlobalSearch extends Vue {

  @Prop({
    type: Boolean,
    required: true
  })
  visible!: boolean;

  errorMessage: string | null = null;
  loading = false;
  query = '';
  results: ProductModel[] = [];

  @Watch('query')
  submit(value: string): void {
    if (value !== '') {
      // Debounce value in case user is still entering the query
      setTimeout(() => {
        if (value === this.query) {
          track(TrackingEventType.Search, {
            search_string: value
          });

          const query = this.query
              .split(' ')
              .map((subValue: string) => `title:${subValue}`)
              .join(' AND ');

          this.loading = true;
          this.errorMessage = null;
          getProducts('RELEVANCE', 'asc', 12, null, query)
              .then(({products}) => {
                if (products.length === 0) {
                  this.errorMessage = this.$t('Components.GlobalSearch.NoResultsFounds', { query: this.query }).toString();
                }

                this.results = products;
              })
              .finally(() => {
                this.loading = false;

                // Refocus on input
                setTimeout(() => {
                  (this.$refs.input as HTMLInputElement).focus();
                });
              });
        }
      }, 500);
    }
  }

  @Watch('visible')
  visibilityChanged(isVisible: boolean): void {
    if (isVisible) {
      this.clearQuery();
      this.results = [];
      (this.$refs.input as HTMLInputElement).focus();
    }
  }

  clearQuery(): void {
    this.query = '';
  }

  close() {
    this.$emit('close');
  }

}
