import { ActionContext, Module } from 'vuex';

import { RootState } from '@/store/index';

// Models
import ProductModel from "@/models/product.model";

// Resources
import { getProductDetails, getProductRecommendations } from "@/resources/products.resource";

export interface ProductDetailsStoreState {
  loading: boolean,
  product: ProductModel | null,
  relatedProducts: ProductModel[]
}

export default class ProductDetailsStoreModule implements Module<ProductDetailsStoreState, RootState>{
  namespaced = true;

  state = {
    loading: false,
    product: null,
    relatedProducts: []
  };

  mutations = {
    setLoading: (state: ProductDetailsStoreState, { loading }: { loading: boolean; }): void => {
      state.loading = loading;
    },

    setProduct: (state: ProductDetailsStoreState, { product }: { product: ProductModel }): void => {
      state.product = product;
    },

    setRelatedProducts: (state: ProductDetailsStoreState, { products }: { products: ProductModel[] }): void => {
      state.relatedProducts = products;
    }
  };

  actions = {
    loadProductDetails: ({ commit }: ActionContext<ProductDetailsStoreState, RootState>, { productId }: { productId: string }) => {
      commit('setLoading', {
        loading: true
      });

      const productDetailsPromise = getProductDetails (productId)
        .then(product => {
          commit('setProduct', {
            product
          });
        });

      const relatedProductsPromise = getProductRecommendations(productId)
        .then(products => {
          commit('setRelatedProducts', {
            products
          });
        })

      return Promise.all([
        productDetailsPromise,
        relatedProductsPromise
      ]).finally(() => {
        commit('setLoading', {
          loading: false
        });
      });
    }
  };
}
