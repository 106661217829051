import RootModel from "@/models/_root.model";
import i18n from "@/config/i18n.config";

export type CartEntryVariantData = {
    name?: string;
    value?: string;
}

export default class CartEntryVariantModel extends RootModel {

  public name: string;
  public value: string;

  constructor(data: CartEntryVariantData) {
    super();

    this.name = data.name || '';
    this.value = data.value || '';
  }

  get localizedName() {
    const nameEntries = this.name.split('||');

    if (i18n.locale === 'en' && !!nameEntries[0]) {
      return nameEntries[0];
    }
    if (i18n.locale === 'pt' && !!nameEntries[1]) {
      return nameEntries[1];
    }

    return this.name;
  }
}
