import FilterModel, {FilterModelData, ProductFilterType} from '@/models/filter.model';

export interface FilterOption {
  value: string | number;
  label: string;
}

export interface MultiselectFilterData extends FilterModelData{
  options: FilterOption[];
}

export default class MultiselectFilterModel extends FilterModel {
  options: FilterOption[];

  constructor (data: MultiselectFilterData) {
    super(data);

    this.type = ProductFilterType.MultiSelect;

    this.options = data.options;
  }

  getFilterQueryFromValue(values: string[]): string {
    if (values.length === 0) {
      return '';
    }

    const query = values
      .map(value => `${this.id}:${value}`)
      .join(' OR ');

    return `(${query})`
  }
}
