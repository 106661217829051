
import { Component, Vue } from 'vue-property-decorator';

// Components
import BackToTopButton from '@/components/back-to-top-button.component.vue';
import CFooter from '@/components/footer.component.vue';
import CookieLayer from '@/components/cookie-layer.component.vue';
import Navbar from '@/components/navbar.component.vue';

declare var ready: () => void;

@Component({
  components: {
    BackToTopButton,
    CFooter,
    CookieLayer,
    Navbar
  }
})
export default class App extends Vue {
  protected mounted() {
    setTimeout(() => {
      ready();
    }, 2000);
  }
}
