
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoadingSpinner extends Vue {

  @Prop({ type: String, default: '' })
  message: string | undefined;

  @Prop({ type: Boolean, default: false })
  overlay: boolean | undefined;

  @Prop({ type: Boolean, required: true })
  show: boolean | undefined;

  get componentClasses() {
    return {
      'c-loading-spinner--overlay': this.overlay,
      'c-loading-spinner--show': this.show
    }
  }

}
