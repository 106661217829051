import axios from 'axios';

// eslint-disable-next-line
export function execute(query: string, variables?: {[key: string]: any}) {
    const method = 'POST';
    const url = 'https://bellabyana.myshopify.com/api/2022-04/graphql.json';
    const data = {
        query,
        variables
    };
    const headers = {
        'X-Shopify-Storefront-Access-Token': 'c1c0b395c814aa0ed80caa931075cd7e'
    };

    return axios({
        method,
        url,
        data,
        headers
    })
        .then(({ data }) => {
            if (data.errors) {
                throw new Error(`Error executing GraphQL request: ${data.errors[0].message}`);
            }

            return data;
        });
}
