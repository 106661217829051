
import { Component, Vue } from 'vue-property-decorator'

// Services
import LocalStorageService from '@/services/local-storage.service';

@Component
export default class CookieLayer extends Vue {

  visible = false;

  protected mounted() {
    setTimeout(() => {
      if (!LocalStorageService.hasCookiePermission()) {
        // Clear all previous cookies
        localStorage.clear();
        sessionStorage.clear();

        document.body.classList.add('no-scroll')

        this.visible = true;
      }
    })
  }

  setCookies(response: boolean) {
    LocalStorageService.setCookiePermission(response);
    document.body.classList.remove('no-scroll')
    this.visible = false;
  }

}
