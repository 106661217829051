declare global {
  function fbq(type: string, eventType: string, eventData?: TrackingEventData): void;
}

export enum TrackingEventType {
  AddToCart = 'AddToCart',
  Contact = 'Contact',
  CustomizeProduct = 'CustomizeProduct',
  InitiateCheckout = 'InitiateCheckout',
  PageView = 'PageView',
  Search = 'Search',
  ViewContent = 'ViewContent'
}

export interface TrackingEventData {
  content_category?: string;
  content_ids?: number[] | string[];
  content_name?: string;
  content_type?: 'product' | 'product_group';
  contents?: {
    id: string;
    quantity: number
  }[];
  currency?: string;
  num_items?: number;
  predicted_ltv?: number;
  search_string?: string;
  status?: boolean;
  value?: number;
}

export function track(type: TrackingEventType, data?: TrackingEventData) {
  fbq('track', type, data);
}
