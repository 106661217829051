import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// Modules
import CartStore, { CartStoreState } from './cart.store';
import ProductDetailsStore, { ProductDetailsStoreState } from "@/store/product-details.store";
import ProductSearchStore, { ProductSearchStoreState } from './product-search.store';

export interface RootState {
  cart: CartStoreState,
  productDetails: ProductDetailsStoreState,
  productSearch: ProductSearchStoreState
}

const store = new Vuex.Store({
  modules: {
    cart: new CartStore(),
    productDetails: new ProductDetailsStore(),
    productSearch: new ProductSearchStore()
  }
});

// Initialize modules
store.dispatch('cart/initialize');

export default store;
