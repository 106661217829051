import { ActionContext, Module } from "vuex";

import { createCart, getCart, setProducts } from '@/resources/cart.resource';
import LocalStorageService from '@/services/local-storage.service';

import CartModel from '@/models/cart.model';
import CartEntryModel from '@/models/cart-entry.model';

import { RootState } from '@/store/index';

const cartIdStorageKey = 'cartId';

export interface CartStoreState {
    cart: CartModel | null,
    loading: boolean
}

export default class CartStoreModule implements Module<CartStoreState, RootState> {
    namespaced = true;

    state ={
        cart: null,
        loading: false
    };

    getters = {
        quantityOfProductInCart: (state: CartStoreState) => (productId: string) => {
            // If no cart is available, return immediately
            if (state.cart === null) {
                return 0;
            }

            const selectedCartEntry = state.cart.entries.find(entry => entry.id === productId);

            return (selectedCartEntry || {}).quantity || 0;
        }
    };

    mutations = {
        SET_CART(state: CartStoreState, { cart }: { cart: CartModel }) {
            state.cart = cart;

            // If cart id is available, save it to local storage for later retrieval, in case of page reload
            if (cart !== null && cart.id) {
                LocalStorageService.setKey(cartIdStorageKey, cart.id);
            }
        },

        SET_LOADING(state: CartStoreState, { loading }: { loading: boolean }) {
            state.loading = loading;
        }
    };

    actions = {
        initialize({ dispatch }: ActionContext<CartStoreState, RootState>) {
            // If there is a cartId saved, load the corresponding cart
            const cartId = LocalStorageService.getKey(cartIdStorageKey);

            if (cartId !== null) {
                return dispatch('loadCart', { cartId });
            }
        },

        addProductToCart({ commit, dispatch, state }: ActionContext<CartStoreState, RootState>, { productId, quantity }: { productId: string, quantity: number }) {
            commit('SET_LOADING', { loading: true });

            let cartCreatedPromise;

            // Check if cart has already been created, if not create it
            if (state.cart === null) {
                cartCreatedPromise = createCart()
                    .then((cart: CartModel) => {
                        commit('SET_CART', { cart });
                        return cart;
                    });
            } else {
                cartCreatedPromise = Promise.resolve(state.cart);
            }

            cartCreatedPromise
                .then((cart: CartModel) => {
                    if (state.cart !== null) {
                        const newCartEntries = state.cart.entries.slice();
                        const selectedCartEntryIndex = newCartEntries.findIndex(entry => entry.id === productId);

                        // If product is already in cart, add the given quantity
                        if (selectedCartEntryIndex !== -1) {
                            return dispatch('setCartEntryQuantity', {
                                productId,
                                quantity: newCartEntries[selectedCartEntryIndex].quantity + quantity
                            });
                        }

                        const cartEntry = new CartEntryModel({
                            id: productId,
                            imageUrl: '',
                            maxQuantity: 10,
                            name: '',
                            price: 0,
                            quantity: quantity,
                            variants: []
                        });
                        newCartEntries.push(cartEntry)
                        return setProducts(cart.id, newCartEntries);
                    }
                })
                .then(cart => {
                    commit('SET_CART', { cart });
                    return cart;
                })
                .finally(() => {
                    commit('SET_LOADING', { loading: false });
                });
        },

        checkoutWithProduct({ commit, dispatch, state }: ActionContext<CartStoreState, RootState>, { productId, quantity }: { productId: string, quantity: number }) {
          commit('SET_LOADING', { loading: true });

          return createCart()
            .then((cart: CartModel) => {
              return setProducts(cart.id, [
                new CartEntryModel({
                  id: productId,
                  imageUrl: '',
                  maxQuantity: 10,
                  name: '',
                  price: 0,
                  quantity: quantity,
                  variants: []
                })
              ]);
            })
            .then(cart => {
              // Navigate to checkout page
              window.location.href = cart.checkoutLink;
            })
            .catch(() => {
              commit('SET_LOADING', { loading: false });
            });
        },

        loadCart({ commit }: ActionContext<CartStoreState, RootState>, { cartId }: { cartId: string }) {
            commit('SET_LOADING', {
                loading: true
            });

            return getCart(cartId)
                .then((cart) => {
                    // Cart no longer exists, delete it from local storage
                    if (cart === null) {
                      LocalStorageService.deleteKey(cartIdStorageKey);
                      return
                    }

                    // Only save cart if the given cart has not been completed yet
                    if (!cart.isCompleted) {
                      commit('SET_CART', { cart });
                    }

                    return cart;
                })
                .finally(() => {
                    commit('SET_LOADING', {
                        loading: false
                    });
                })
        },

        removeCartEntry({ commit, state }: ActionContext<CartStoreState, RootState>, { productId }: { productId: string }) {
            if (state.cart !== null) {
                const newCartEntries = state.cart.entries.slice();
                const selectedCartEntryIndex = newCartEntries.findIndex(entry => entry.id === productId);

                if (selectedCartEntryIndex !== -1) {
                    newCartEntries.splice(selectedCartEntryIndex, 1);

                    commit('SET_LOADING', {
                        loading: true
                    });
                    setProducts(state.cart.id, newCartEntries)
                        .then((cart: CartModel) => {
                            commit('SET_CART', { cart });
                            return cart;
                        })
                        .finally(() => {
                            commit('SET_LOADING', {
                                loading: false
                            });
                        });
                }
            }
        },

        setCartEntryQuantity({ commit, state }: ActionContext<CartStoreState, RootState>, { productId, quantity }: { productId: string, quantity: number }) {
            if (state.cart !== null) {
                const newCartEntries = state.cart.entries.slice();
                const selectedCartEntryIndex = newCartEntries.findIndex(entry => entry.id === productId);

                if (selectedCartEntryIndex !== -1) {
                    newCartEntries[selectedCartEntryIndex].quantity = quantity;

                    commit('SET_LOADING', { loading: true });
                    return setProducts(state.cart.id, newCartEntries)
                        .then((cart: CartModel) => {
                            commit('SET_CART', { cart });
                            return cart;
                        })
                        .finally(() => {
                            commit('SET_LOADING', { loading: false });
                        });
                }
            }
        }
    }
}
