
import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

// Config
import { RouteNames } from '@/config/router.config';

// Components
import GlobalSearch from '@/components/global-search.component.vue';
import LanguagePicker from '@/components/language-picker.component.vue';

// Resources
import { getNavigationCollections } from '@/resources/collections.resource';

interface RouteDefinition {
  name: TranslateResult;
  to: RawLocation;
}

@Component({
  components: {
    GlobalSearch,
    LanguagePicker
  }
})
export default class Navbar extends Vue {

  open = false;
  showShopSubMenu = false;
  showSearch = false;

  navLinks: RouteDefinition[] = [
    {
      name: this.$t('Views.About.Title'),
      to: {
        name: RouteNames.About
      }
    },
    {
      name: this.$t('Views.Partners.Title'),
      to: {
        name: RouteNames.Partners
      }
    },
    {
      name: this.$t('Views.Contacts.Title'),
      to: {
        name: RouteNames.Contacts
      }
    }
  ];
  shopLinks: RouteDefinition[] = [];

  get numberOfItemsInCart(): number {
    const cart = this.$store.state.cart.cart;

    if (cart === null) {
      return 0;
    }

    return cart.entries.length;
  }

  protected created() {
    this.loadShopLinks();
  }

  loadShopLinks() {
    getNavigationCollections()
        .then(collections => {
          this.shopLinks = collections.map(collection => ({
            name: collection.title,
            to: {
              name: RouteNames.Collection,
              params: {
                collectionId: collection.id
              }
            },
          }));
          this.shopLinks.push({
            name: this.$t('Components.Navigation.ShopAll'),
            to: {
              name: RouteNames.Shop
            }
          });
        });
  }
  toggleShopSubMenu(open?: boolean) {
    const handleDocumentClick = () => {
      this.toggleShopSubMenu(false);
      document.removeEventListener('click', handleDocumentClick);
    }

    this.showShopSubMenu = typeof open !== 'undefined' ? open : !this.showShopSubMenu;

    if (this.showShopSubMenu) {
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick);
      });
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }
  }
  toggleNavbar(open?: boolean) {
    this.open = typeof open !== 'undefined' ? open : !this.open;
  }
}
