import Vue from 'vue';
import VueI18n from 'vue-i18n';

import localStorageService from '@/services/local-storage.service';

// Selected language cookie key
export const SELECTED_LOCALE_KEY = 'locale';

export const DEFAULT_LOCALE = 'en';

// Translations files
import en from '@/assets/translations/en';
import pt from '@/assets/translations/pt';

Vue.use(VueI18n);

export default new VueI18n({
    locale: localStorageService.getKey(SELECTED_LOCALE_KEY) || DEFAULT_LOCALE,
    messages: {
        en,
        pt
    }
})
