
  import {Component, Vue} from 'vue-property-decorator';

  @Component
  export default class BackToTopButton extends Vue {

    private show = false;

    protected created(): void {
      window.addEventListener('scroll', this.handleScrolling);
    }

    protected destroyed(): void {
      window.removeEventListener('scroll', this.handleScrolling);
    }

    scrollToTop(): void {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    handleScrolling(): void {
      this.show = window.pageYOffset > 0;
    }
  }
