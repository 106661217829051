const COOKIES_KEY = 'cookies-permission';

function getKey(key: string) {
    const value = localStorage.getItem(key);

    // Attempt to parse value as JSON in case it's an object
    if (value !== null) {
        try {
            return JSON.parse(value);
        }
        catch {
            return value;
        }
    }
    return value;
}

function setKey(key: string, value: any) {
    // Only save cookies if users has given permission
    if (getKey(COOKIES_KEY) === true) {
        // If value is object, stringify it
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }

        localStorage.setItem(key, value);
    }
}

function deleteKey(key: string) {
    localStorage.removeItem(key);
}

function hasCookiePermission() {
    return getKey(COOKIES_KEY) !== null;
}

function setCookiePermission(permission: boolean) {
    localStorage.setItem(COOKIES_KEY, permission.toString())
}

export default {
    getKey,
    setKey,
    deleteKey,

    hasCookiePermission,
    setCookiePermission
};
