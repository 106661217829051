
import { Component, Prop, Vue } from 'vue-property-decorator';

// Config
import { RouteNames } from '@/config/router.config';

// Models
import ProductModel from '@/models/product.model';

@Component
export default class ProductTile extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  product!: ProductModel;

  currentImageIndex = 0;
  imageSwitchingInterval: number | null = null;

  get productPageLink() {
    return {
      name: RouteNames.ProductDetails,
      params: {
        id: this.product.id
      }
    }
  }

  toggleImageSwitching(start: boolean) {
    if (start) {
      this.currentImageIndex = 1;
      this.imageSwitchingInterval = setInterval(() => {
        this.currentImageIndex++;

        if (this.currentImageIndex > 2) {
          this.currentImageIndex = 0;
        }
      }, 2000)
    }
    else if (this.imageSwitchingInterval !== null) {
      clearInterval(this.imageSwitchingInterval);
      this.currentImageIndex = 0;
    }
  }
}
