import RootModel from './_root.model';

// Config
import i18n from '../config/i18n.config';

// Models
import ProductVariantModel from '@/models/product-variant.model';
import ProductVariantOptionModel from '@/models/product-variant-option.model';

export type ProductModelData = {
    description?: string;
    id: string;
    images?: string[];
    name: string;
    price?: number;
    tag?: string;
    variants?: ProductVariantModel[]
    variantOptions?: ProductVariantOptionModel[]
}

export default class ProductModel extends RootModel {

    description: string;
    id: string;
    images: string[];
    name: string;
    tag: string;
    variants: ProductVariantModel[];
    variantOptions:ProductVariantOptionModel[];

    constructor(data: ProductModelData) {
        super();

        this.description = data.description || '';
        this.id = data.id || '';
        this.images = data.images || [];
        this.name = data.name || '';
        this.tag = data.tag || '';
        this.variants = data.variants || [];
        this.variantOptions = data.variantOptions || [];
    }

    get price() {
        return Math.min(...this.variants.map(variant => variant.price));
    }

    get oldPrice() {
      return Math.min(...this.variants.map(variant => variant.oldPrice));
    }

    get localizedName() {
      const nameEntries = this.name.split('||');

      if (i18n.locale === 'en' && !!nameEntries[0]) {
        return nameEntries[0];
      }
      if (i18n.locale === 'pt' && !!nameEntries[1]) {
        return nameEntries[1];
      }

      return this.name;
    }

    get localizedDescription() {
      const element = document.createElement('html');
      element.innerHTML = this.description;

      const localizedSection = element.querySelectorAll(`[lang="${i18n.locale}"]`);

      if (localizedSection.length > 0) {
        return localizedSection[0].innerHTML;
      }
      else {
        return this.description;
      }
    }
}
