import RootModel from './_root.model';
import i18n from "@/config/i18n.config";

export type ProductVariantOptionData = {
  id?: string;
  options?: {
    label: string;
    value: string;
  }[]
}

export default class ProductVariantOptionModel extends RootModel {

    id: string;
    options: {
        label: string;
        value: string;
    }[];

    constructor (data: ProductVariantOptionData) {
        super();

        this.id = data.id || '';
        this.options = data.options || []
    }

    get localizedName() {
      const nameEntries = this.id.split('||');

      if (i18n.locale === 'en' && !!nameEntries[0]) {
        return nameEntries[0];
      }
      if (i18n.locale === 'pt' && !!nameEntries[1]) {
        return nameEntries[1];
      }

      return this.id;
    }

}
