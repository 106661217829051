import RootModel from "@/models/_root.model";
import i18n from "@/config/i18n.config";
import CartEntryVariantModel from "@/models/cart-entry-variant.model";

export type CartEntryData = {
  id?: string;
  imageUrl?: string;
  maxQuantity?: number;
  name?: string;
  price?: number;
  oldPrice?: number;
  quantity?: number;
  variants?: CartEntryVariantModel[];
}

export default class CartEntryModel extends RootModel {

  public id: string;
  public imageUrl: string;
  public maxQuantity: number;
  public name: string;
  public price: number;
  public oldPrice: number;
  public quantity: number;
  public variants: CartEntryVariantModel[];

  constructor(data: CartEntryData) {
    super();

    this.id = data.id || '';
    this.imageUrl = data.imageUrl || '';
    this.maxQuantity = data.maxQuantity || 1;
    this.name = data.name || '';
    this.price = data.price || 0;
    this.oldPrice = data.oldPrice || NaN;
    this.quantity = data.quantity || 1;
    this.variants = data.variants || [];
  }

  get localizedName() {
    const nameEntries = this.name.split('||');

    if (i18n.locale === 'en' && !!nameEntries[0]) {
      return nameEntries[0];
    }
    if (i18n.locale === 'pt' && !!nameEntries[1]) {
      return nameEntries[1];
    }

    return this.name;
  }
}
