import RootModel from './_root.model';

export default class ProductVariantModel extends RootModel {

    id: string;
    price: number;
    oldPrice: number;
    availableForSale: boolean;
    availableQuantity: number;
    variantIdentifiers: {
        id: string,
        value: string
    }[]

    constructor (data: ProductVariantModel) {
        super();

        this.id = data.id;
        this.price = data.price || NaN;
        this.oldPrice = data.oldPrice || NaN;
        this.availableForSale = data.availableForSale;
        this.availableQuantity = data.availableQuantity;
        this.variantIdentifiers = data.variantIdentifiers;

    }
}
