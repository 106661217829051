import CartEntryModel from '@/models/cart-entry.model';
import RootModel from '@/models/_root.model';

export default class CartModel extends RootModel {

    public id: string;
    public totalPrice: string;
    public checkoutLink: string;
    public entries: Array<CartEntryModel>;
    public isCompleted: boolean;

    constructor(data: CartModel) {
        super();

        this.id = data.id;
        this.totalPrice = data.totalPrice;
        this.checkoutLink = data.checkoutLink;
        this.entries = data.entries;
        this.isCompleted = data.isCompleted;
    }

}
